.side {
  width: 16%;
  background-color: rgb(213, 221, 218);
  height: 100vh; 
  position: fixed; 
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.side .row {
  width: 100%;
  height: 50px;
  border: 1px solid rgb(125, 124, 124);
  margin: 0;
  color: rgb(95, 115, 116);
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.side .row:hover {
  background-color: #a2a5ab;
}

.signout {
  margin-top: auto; /* Push the button to the bottom */
}